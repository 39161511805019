<template>
  <section>
    <v-container>
      <div class="mt-2">
        <div>
          <v-parallax src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/retail/store-front.jpg" class="parallax-title rounded-lg" style="height: 400px;">
            <v-overlay :absolute="true" :opacity="0.8">
              <div class="pa-10 text-center">
                <div class="goBold-5 pb-4">
                  Meet our Team
                </div>
              </div>
            </v-overlay>
          </v-parallax>
        </div>
      </div>
      <div class="mt-12">
        <div>
          <v-row class="hidden-sm-and-down">
            <v-col v-for="(teamMember, index) in teamMembers" :key="index" class="d-flex justify-center mb-12">
              <div class="rounded-lg" style="position: relative;">
                <cropped-image-viewer :image="teamMember.image.url" :coordinates="teamMember.image.coordinates" :width="420" :height="420" classes="rounded-lg elevation-4">
                  <v-overlay :absolute="true" :opacity="0.35" z-index="4" class="primary-content insetShadow rounded-lg">
                    <div style="position: relative; width: 100%; height: 100%;">
                      <div class="pa-4" style="position: absolute; bottom: 0; width: 100%;">
                        <div class="goBold-4">
                          <p v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ teamMember.publicName }}</p>
                        </div>
                        <div class="antonio-2">
                          <p v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ teamMember.title }}</p>
                        </div>
                        <div class="antonio-1">
                          <p v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ teamMember.email }}</p>
                        </div>
                        <outlined-white-button @click="doOpenTeamMember(index)" block>About {{ teamMember.firstName }}</outlined-white-button>
                      </div>
                    </div>
                  </v-overlay>
                </cropped-image-viewer>
              </div>
            </v-col>
          </v-row>

          <v-row class="hidden-md-and-up" no-gutters>
            <v-col v-for="(teamMember, index) in teamMembers" :key="index" class="d-flex justify-center mb-12">
              <div>
                <v-card class="team-member-card rounded-lg" elevation="0">
                  <div class="text-center">
                    <cropped-image-viewer :image="teamMember.image.url" :coordinates="teamMember.image.coordinates" :width="300" :height="300" classes="rounded-lg elevation-0 mx-4 mt-4"></cropped-image-viewer>
                  </div>
                  <v-card-title>
                    <div class="font-weight-bold goBold-4 mt-2">{{ teamMember.publicName }}</div>
                  </v-card-title>
                  <v-card-text>
                    <div class="font-weight-regular antonio-2 mt-1">{{ teamMember.title }}</div>
                    <div class="font-weight-regular antonio-1 mt-1">{{ teamMember.email }}</div>
                    <div class="text-right mt-4">
                      <outlined-auto-button @click="doOpenTeamMember(index)" block>About {{ teamMember.firstName }}</outlined-auto-button>
                    </div>
                  </v-card-text>
                </v-card>
                <v-divider class="mt-4"></v-divider>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
    <div style="position:fixed; top: 5px; right: 5px; z-index: 1000;" v-if="aboutMemberOpen">
      <v-btn @click="doCloseTeamMember" class="red--text"><font-awesome-icon :icon="['fas', 'x']"/></v-btn>
    </div>
    <v-dialog v-model="aboutMemberOpen" max-width="600px">
      <v-card>
        <v-img :src="viewingMember.image.url" max-height="350" contain></v-img>
        <v-card-title class="mb-0 pb-0">
          <div class="goBold-4">{{ viewingMember.publicName }}</div>
        </v-card-title>
        <v-card-text>
          <div class="antonio-2 mt-1">{{ viewingMember.title }}</div>
          <div class="antonio-1 mt-1">{{ viewingMember.email }}</div>
          <div class="gothic-1 mt-3" style="line-height: 20px;">{{ viewingMember.about }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <wave-effect></wave-effect>
  </section>
</template>

<script>
import CroppedImageViewer from '@/components/image/croppedImageViewer.vue'
import OutlinedWhiteButton from '@/components/outlinedWhiteButton.vue'
import OutlinedAutoButton from '@/components/outlinedAutoButton.vue'
import WaveEffect from '@/components/waveEffect.vue'

export default {
  name: 'Team',
  components: { WaveEffect, OutlinedAutoButton, OutlinedWhiteButton, CroppedImageViewer },
  data: () => ({
    isLoading: false,
    aboutMemberOpen: false,
    viewingMember: {
      image: {
        url: null
      },
      publicName: null,
      title: null,
      email: null,
      about: null
    },
    teamMembers: []
  }),
  methods: {
    doLoadTeamMembers () {
      this.isLoading = true

      this.$services.teamMember.doGetAllPublic().then(teamMembers => {
        this.teamMembers = teamMembers
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doOpenTeamMember (index) {
      this.viewingMember = this.teamMembers[index]
      this.aboutMemberOpen = true
    },
    doCloseTeamMember () {
      this.aboutMemberOpen = false
      this.viewingMember = {
        image: {
          url: null
        },
        publicName: null,
        title: null,
        email: null,
        about: null
      }
    }
  },
  mounted () {
    this.doLoadTeamMembers()
  },
  created () {
    this.doSetDocumentTitle('Our team', true)
  }
}
</script>

<style scoped>
.blurry {
  filter: blur(40px);
  -webkit-filter: blur(40px);
  cursor: pointer !important;
  pointer-events: none !important;
}

.team-member-card {
  height: 400px;
  width: 500px;
}

/* Going to LG */
@media only screen and (max-width: 1904px) {
  .team-member-card {
    height: 400px;
    width: 860px;
  }
}

/* Going to MD */
@media only screen and (max-width: 1264px) {
  .team-member-card {
    height: 400px;
    width: 575px;
  }
}

/* Going to SM */
@media only screen and (max-width: 960px) {
  .team-member-card {
    height: 100%;
    width: 100%;
  }
}

::v-deep .primary-content .v-overlay__content {
  width: 100%;
  height: 100%;
}

.insetShadow {
  -webkit-box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
  -moz-box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
  box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
}

</style>
