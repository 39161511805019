<template>
  <section>
    <div style="position: relative; width: 100%; height: 100%;">
      <canvas ref="imageCanvas" :width="`${width}px`" :height="`${height}px`" :class="classes"></canvas>
      <img ref="imageSrc" :src="image" style="display: none;" @load="drawImage">
      <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; width: 100%; height: 100%;">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'croppedImageViewer',
  props: {
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    image: {
      type: String,
      default: String()
    },
    classes: {
      type: String,
      default: 'rounded-lg elevation-6'
    },
    coordinates: {
      type: Object,
      default: function () {
        return {
          top: 0,
          left: 0,
          width: 0,
          height: 0
        }
      }
    }
  },
  methods: {
    drawImage () {
      const context = this.$refs.imageCanvas.getContext('2d')
      context.drawImage(this.$refs.imageSrc, this.coordinates.left, this.coordinates.top, this.coordinates.width, this.coordinates.height, 0, 0, this.width, this.height)
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
</style>
